// 所有页面都使用本mixin
export default {
    // head() {
    //     return {
    //         title: this.webInfo.webName,
    //         link: [
    //             { rel: 'icon', type: 'image/x-icon', href: this.webInfo.iconUrl }
    //         ],
    //     };
    // },
    provide() {
        return {
            webInfo: this.webInfo,
        };
    },
    methods: {

    },
    mounted() {
        this.$store.commit('updateWebInfo', this.webInfo);

        window.webInfo = this.webInfo;
    },
};
