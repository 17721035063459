<template>
    <div class="header-fybh">
        <div class="header-fybh-fixed">
            <div class="inner">
                <div class="logo">
                    <img :src="logoUrl" alt="logo" />
                </div>

                <div class="nav">
                    <ul class="nav_ul">
                        <li
                            @click="tabClick(item)"
                            v-for="(item, index) in webInfo.webDhlj"
                            :key="index"
                            ref="tab"
                            :class="[index == lindex ? 'nav_active' : '']"
                        >
                            <a target="_parent">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>

                <div class="concat">
                    <div class="row">
                        <div class="icon" :style="tel"></div>
                        <div class="text">
                            <div class="btn_active">{{ webInfo.phone1 }}</div>

                            <div v-if="webInfo.phone2" class="btn_active">
                                {{ webInfo.phone2 }}
                            </div>
                        </div>
                    </div>
                    <div v-if="webInfo.email" class="row">
                        <div class="icon" :style="emailstyle"></div>
                        <div class="text">
                            <span class="btn_active">
                                {{ webInfo.email }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
    data: function () {
        return {
            tel: {
                background: 'url(/branch/fybh/images/tel.png) no-repeat',
            },
            emailstyle: {
                background: 'url(/branch/fybh/images/email.png) no-repeat',
            },
            logoUrl: '/images/branch/fybh/logo2.png',
        };
    },
    props: {
        lindex: {
            type: Number,
            default: 0,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
    watch: {},
    methods: {
        tabClick: function (item) {
            if (item.index == 0) {
                window.location.replace('http://' + window.location.host);
            } else {
                window.location.href = item.url;
            }
        },
    },
    mounted: function () {},
};
</script>

<style lang="scss" scoped>
$innerWidth: 1350px;

.header-fybh {
    height: 90px;
    width: 100%;
    min-width: $innerWidth;
    height: 90px;

    .header-fybh-fixed {
        position: fixed;
        z-index: 1000;
        width: 100%;
        margin: 0 auto;
        background-color: #20a4ff;

        .inner {
            display: flex;
            width: $innerWidth;
            margin: 0 auto;

            .logo {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 40px;
                }
            }

            .nav {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                float: none;
                min-width: 800px;
                flex: 1;

                .nav_ul {
                    position: relative;
                    margin-left: 20px;
                    overflow: hidden;
                    height: 90px;
                    line-height: 90px;
                    font-size: 18px;

                    li {
                        cursor: pointer;
                        height: 100%;
                        float: left;
                        margin-right: 20px;

                        a {
                            color: white;
                        }
                    }
                }
            }

            .concat {
                font-size: 14px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .row {
                    position: relative;

                    .icon {
                        background-size: 20px 20px;
                        width: 20px;
                        height: 100%;
                        display: block;
                        position: absolute;
                        left: 0;
                        background-position: center;
                    }

                    .text {
                        padding-left: 25px;
                    }

                    .btn_active {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 1.8;
                        color: white;
                    }
                }
            }
        }
    }
}
</style>