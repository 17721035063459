<template>
    <div class="apply-header-con">
        <div class="apply-header">
        </div>
        <div class="apply-welcome-info">
            <h1 class="info-welcome">欢迎来到{{webInfo.webName}}！</h1>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
}
</script>

<style lang="scss" scoped>
.apply-header-con {
    position: relative;
    min-height: 300px;
    padding-top: 217px;
    background-image: url(/images/apply/banner-apply.jpg);
    background-size: auto;
    background-position: center top;
    background-repeat: no-repeat;
}
/* .apply-header{width:100%; height:300px; position:absolute; z-index:0; } */
/* .apply-header img{height:300px; position:absolute; left:50%; top:50%; transform:translate(-50%, -50%); } */
.apply-welcome-info {
    width: 1500px;
    margin: 0 auto 1em;
    background: white;
    text-align: center;
    padding: 34px 0;
    font-weight: bold;
}
.apply-welcome-info .info-welcome {
    font-size: 30px;
    color: #333333;
    font-weight: bold;
}
.apply-welcome-info .info-font {
    font-size: 16px;
    color: #4bb4f1;
}
.apply-welcome-info .info-font span + span {
    margin-left: 4em;
}
</style>