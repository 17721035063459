<template>
    <div class="online-serve">
        <div
            style="color:#ee6e26;"
            class="item"
            @click="goOnlineServer"
        >
            <div class="iconfont img_transform_area">&#xe625;</div>

            <p class="text">在线客服</p>
        </div>

        <!-- <el-popover
                placement="left-start"
                width="200"
                trigger="hover"
            >
                <div class="popover">
                    <span>客服1：1738309192</span><br>
                    <span>客服2：1770319855</span><br>
                    <span>客服3：3193079286</span><br>
                </div>
                <div slot="reference">
                    <div class="item border">
                        <i
                            class="iconfont"
                        >&#xe646;</i>
                        <p>QQ</p>
                    </div>
                </div>
            </el-popover> -->

        <el-popover
            placement="left-start"
            width="150"
            trigger="hover"
        >
            <div class="popover">
                <img
                    src="/images/homePage/wx-qrcode-zlx.jpg"
                    alt="二维码"
                    style="width:125px"
                />
            </div>

            <div slot="reference">
                <div class="item border">
                    <i
                        class="iconfont"
                        style="color: #1CD66C;"
                    >&#xe63d;</i>
                    <p class="text">微信号</p>
                </div>
            </div>
        </el-popover>

        <el-popover
            placement="left-start"
            width="150"
            trigger="hover"
        >
            <div class="popover">
                <img
                    :src="webInfo.qrcodeUrl"
                    alt="二维码"
                    style="width:125px"
                />
            </div>

            <div slot="reference">
                <div class="item border">
                    <i class="iconfont">&#xe631;</i>
                    <p class="text">公众号</p>
                </div>
            </div>
        </el-popover>

        <el-popover
            placement="left-start"
            width="220"
            trigger="hover"
            content="邮箱：hb@zhbykj.com"
        >
            <div class="popover">
                <span>电话：{{tel}}</span><br>
            </div>

            <div slot="reference">
                <div class="item border">
                    <i class="iconfont">&#xe94f;</i>
                    <p class="text">电话</p>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
    computed: {
        tel: function () {
            return this.webInfo.phone1.split('（')[0]
                ? this.webInfo.phone1.split('（')[0]
                : '400-780-9998';
        },
    },
    watch: {},
    methods: {
        goOnlineServer: function () {
            window.open('/web/online-service/index.html');
        },
    },
    mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.online-serve {
    position: fixed;
    top: 58%;
    right: 0px;
    transform: translate(0px, -50%);
    z-index: 999;
    line-height: normal;
    background: white;
    border: 1px solid #dcdfe6;

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80px;
        height: 70px;
        cursor: pointer;
        transition: all .3s;

        &.border {
            border-top: 1px solid #dcdfe6;
        }

        &:hover{
            background: #eee;
        }

        .iconfont {
            font-size: 28px;
            font-weight: normal;
        }

        .text {
            line-height: 1em;
            margin-top: 4px;
        }
    }

    .img_transform_area {
        animation: rotate 2s infinite;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(25deg);
    }
    40% {
        transform: rotate(-23deg);
    }
    60% {
        transform: rotate(20deg);
    }
    80% {
        transform: rotate(-13deg);
    }
    100% {
        transform: rotate(3deg);
    }
}
</style>