<template>
    <div class="ggzy-header-sys">
        <div class="name">{{ webInfo.webName }}</div>
        <ul class="nav-all">
            <li
                v-for="(item, index) in webInfo.webDhlj"
                :key="index"
                :class="{ isActived: item.url === $route.path }"
            >
                <a :href="item.url" :target="item.target">{{ item.title }}</a>
            </li>
        </ul>
        <div class="concat">服务热线：{{ webInfo.phone1 }}</div>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
    data: function () {
        return {};
    },
    props: {},
    computed: {},
    watch: {},
    methods: {},
    mounted: function () {},
};
</script>

<style lang="scss" scoped>
.ggzy-header-sys {
    display: flex;
    background-color: #0154a4;
    align-items: center;
    justify-content: space-between;
    padding: 0 38px;
    height: 86px;
    position: sticky;
    top: 0;
    z-index: 99;

    .name {
        font-size: 30px;
        font-weight: bold;
        color: white;
    }

    .nav-all {
        display: flex;

        li {
            a {
                display: inline-block;
                padding: 0 10px;
                font-size: 20px;
                color: white;
                cursor: pointer;

                &:hover {
                    color: #3878dc;
                }

                &.isActived {
                    color: #3878dc;
                }
            }
        }
    }

    .concat {
        font-size: 16px;
        color: white;
        width: 360px;
        text-align: right;
    }
}
</style>